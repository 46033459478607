/* ResponsiveMenu.css */
.responsive-menu {
    background-color: #00105E;
    color: white;
    padding: 10px;
}

.menu-toggle {
    cursor: pointer;
    font-size: 24px;
}

.menu-items {
    list-style-type: none;
    padding: 0;
    display: none; /* Nascondi il menu per default */
}

.menu-items.open {
    display: block; /* Mostra il menu quando è aperto */
}

.menu-items li {
    margin: 10px 0;
    background-color: #333;
}

.menu-items a {
    color: white;
    text-decoration: none;
}

.menu-items a:hover {
    text-decoration: underline;
}
