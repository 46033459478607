.button-container {
    display: flex; /* Utilizza Flexbox per allineare i pulsanti */
    justify-content: center; /* Centra i pulsanti orizzontalmente */
    margin: 20px 0; /* Margine sopra e sotto il contenitore dei pulsanti */
  }
  
  .rounded-button {
    background-color: #f0f0f0 !important; /* Colore di sfondo */
    color: rgb(129, 129, 129) !important; /* Colore del testo */
    border: none; /* Rimuovi il bordo */
   
    padding: 12px 20px; /* Padding interno */
    margin: 0 10px; /* Margine tra i pulsanti */
    cursor: pointer; /* Cambia il cursore al passaggio del mouse */
    font-size: 16px; /* Dimensione del testo */
    transition: background-color 0.3s, transform 0.2s; /* Transizione per l'effetto hover */
  }
  
  .rounded-button:hover {
    background-color: #d4d4d4 !important; /* Colore al passaggio del mouse */
    transform: scale(1.05); /* Leggero ingrandimento al passaggio del mouse */
  }
  
  .rounded-button:focus {
    outline: none; /* Rimuovi il contorno di focus predefinito */
  }
  