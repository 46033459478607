.valore{
    display: inline;
    font-size: 1em;
    font-weight: 600;
    color: #00105E;
}



.user-detail-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.user-detail {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #333;
}

.user_user_detail{
    color: #666666;
    font-weight: 100;
    padding: 20px;
    background-color: #f6f6ff;
}

.log-details{
    width: 100%;
    padding: 15px;

}

.user-detail p {
    margin: 10px 0;
    font-size: 16px;
}

.user-detail p strong {
    color: #333;
}

.email-info {
    margin-top: 20px;
}

.email-info h2 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #00105E;
}

.user-header h1 {
    margin-bottom: 10px;
    font-size: 23px;
    color: #00105E;
}

.icon_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-info h2 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #00105E;
}

.email-info ul {
    list-style-type: none;
    padding: 0;
}

.email-info li {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.email-info li p {
    margin: 5px 0;
}

.email-info li p strong {
    color: #CEB26A;
}

.email-info .log-details {
    margin-top: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    white-space: pre-wrap;
    font-family: monospace;
    width: 100%;
    position: relative;
}

.email-info button {
    margin: 10px 5px 0 0;

    padding: 5px 10px;
    border: none;
    background-color: #CEB26A;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 4px;
}

.email-info button:hover {
    background-color: #8A753F;
}

.json-line {
    white-space: pre-wrap; /* Mantiene i ritorni a capo e gli spazi */
    word-wrap: break-word; /* Permette di andare a capo all'interno delle parole */
    overflow-wrap: break-word; /* Permette di andare a capo all'interno delle parole */
    max-width: 100%; /* Assicura che il div non superi la larghezza del contenitore */
    overflow: hidden; /* Nasconde il contenuto che eccede la larghezza del contenitore */
}

.refunded_label{
    background-color: #ffe8b7;
    padding: 10px;
}


.json-key {
    display: inline;
    font-weight: bold;
    color: #CEB26A; /* Colore per evidenziare la chiave */
}

.json-value {
    color: #333; /* Colore per il valore */
    margin-left: 5px;
    display: inline;
}

.payment_actions{
    display: flex;
    text-align: right;
    align-items: right;
    margin-top: 20px;
}

.payment_actions svg{
    cursor: pointer;
    font-size: 24px;
    color: #333;
    
}
.action_button{
    width: 65px;
    background-color: #59420599 !important;
    padding: 5px 10px !important;
    border-radius: 5%;
    text-align: center;
    color: white;
    cursor: pointer;
}

.action_button:hover{
    background-color: #594205 !important;
}

/********** USER IMAGe */


.user-header {
    display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0eeee;
  padding: 20px;
  margin-top: 20px;
}

.user-header h1 {
    margin: 0;
}

.user-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ccc;
}


/*------------------------ PAYMENT SYSTEM */

.payment_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-icon-container {
    display: inline-block;

}

.payment-icon {
    width: 100%;
    height: 100%;
}


/*------------- AZIONI UTENTE ---------------*/

.user-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.action-icon {
    cursor: pointer;
    font-size: 24px;
    color: #333;
}

.action-icon:hover {
    color: #CEB26A;
}



/*------ TOOLTIP ------*/

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-container .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiziona il tooltip sopra l'icona */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Punta la freccia verso il basso */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

