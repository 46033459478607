.users-container {
    padding: 20px;
    position: relative;
}

.filters {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    padding: 20px;
    transition: left 0.3s ease;
    z-index: 1000;
}

.filters.open {
    left: 0;
}

.filters .close-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.filters input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.open-filters-btn {
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

table th {
    background-color: #f2f2f2;
}

.pagination {
    display: flex;
  gap: 5px;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
}

.pagination button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f2f2f2;
    cursor: pointer;
    max-width: 40px;
}

.pagination button.active {
    background-color: #ceb26a;
    color: white;
}

.userOnTable{
    cursor: pointer;
}


.userOnTable:hover{
    background-color: azure;
}

.open-filters-btn {
    padding: 5px 10px;
    margin-bottom: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    width: 100px;
}

.open-filters-btn:hover {
    background-color: #0056b3;
}

.speedy_filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.speedy_filters > div {
    display: flex;
    flex-direction: column;
}

.speedy_filters label {
    margin-bottom: 5px;
    font-weight: bold;
}

.speedy_filters select,
.speedy_filters input {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.speedy_filters select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
}

.speedy_filters select:focus,
.speedy_filters input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}